// Loan amount slider and input
const loanAmountSlider = document.querySelector('#js-amount-range');
const loanAmountInput = document.querySelector('#js-amount-input');

if (loanAmountSlider && loanAmountInput) {
    const minValue = loanAmountSlider.getAttribute('min');
    const maxValue = loanAmountSlider.getAttribute('max');
    const defaultValue = loanAmountSlider.dataset.default;
    const numberFormatter = Intl.NumberFormat("nl-NL");

    // Get the numeric value from an event, the target can either be the slider or the input.
    const getNumericInputValue = function (event) {
        return +(event.target.value?.replaceAll(",", "").replaceAll(".", ""));
    }

    // Format the numeric value.
    const formatInputValue = function (value) {
        return numberFormatter.format(value);
    }

    // Update the background horizontal size based on the slider value.
    const updateLoadSlider = function (value) {
        loanAmountSlider.style.backgroundSize = (value - minValue) * 100 / (maxValue - minValue) + '% 100%'
    }

    // Update the selected loan amount whenever the slider or input changes.
    const updateLoanAmount = function (event) {
        let value = getNumericInputValue(event);

        if (isNaN(value)) {
            value = defaultValue;
        } else if (value < minValue) {
            value = minValue;
        } else if (value > maxValue) {
            value = maxValue;
        }

        loanAmountInput.value = formatInputValue(value);
        loanAmountSlider.value = value;

        updateLoadSlider(value);
    };

    // Register listeners on both the slider and input.
    loanAmountSlider.addEventListener('input', event => updateLoanAmount(event));
    loanAmountInput.addEventListener('blur', event => updateLoanAmount(event));
    loanAmountInput.addEventListener('focus', event => event.target.value = getNumericInputValue(event));

    // Initialize the default values on both the slider and input.
    loanAmountInput.value = formatInputValue(defaultValue);
    updateLoadSlider(defaultValue);
}


const button = document.getElementById('loan_calc_button');
if(button){
    button.addEventListener('click', function(event) {

        // Prevent page refresh
        event.preventDefault();

        const inputValue = document.getElementById('js-amount-input').value;
        const baseUrl = button.getAttribute('data-url')
        if (baseUrl === '#') {
            return false;
        }
        const fullUrl = baseUrl + '/?aanschafprijs=' + inputValue;
        window.location.href = fullUrl;
        // window.open(fullUrl, '_blank');
    });
    $(document).ready(function(){
        $('input#loan_calc_button').tooltip();
    });
}

